@import '../../styles/main.scss';

.products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .products-container {
    margin: 0.4rem 0 15rem 0;
  }

  .products-heading {
    flex-direction: column;
    font-size: 2.5rem;
    text-align: center;
      @include media(xs) {
      font-size: 1.8rem;
      margin-top: 100px;
    }
    @include media(sm) {
      font-size: 2.5rem;
      margin-top: 200px;
    }
    @include media(lg) {
      font-size: 2.5rem;
      margin-top: 200px;
    }
  }
}
