@import '../../../styles/main.scss';

.card-register {
  margin: 1rem 0;
  text-align: left !important;

  .card-register__container-title,
  .card-register__container-action {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 400;
  }

  .card-register__container-action {
    margin-top: 1rem !important;
    a {
      font-size: 11px !important;
    }
  }
  .card-register__btn-container {
    display: flex;
    justify-content: center;
    .card-register__btn-login {
      border: none;
      text-decoration-line: underline !important;
      box-shadow: none;
      color: $primary-color;
    }
  }
}

.data__picker{
    border-radius: 0.8rem;
    height: 38px;
}

small {
  color: red;
}
