@import '../../styles/main.scss';

.home {
  position: relative;
  background: $gradient-bg;
  height: 85vh;
  width: 100%;
  padding-top: 7vh;
  @include media(md) {
    height: 74vh;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-top: 15vw solid transparent;
    border-right: 99.08vw solid white;
    pointer-events: none;
    @include media(xs) {
      border-right: 100vw solid white;
    }
    @include media(sm) {
      border-right: 100vw solid white;
    }
    @include media(md) {
      border-right: 100vw solid white;
    }
    @include media(lg) {
      border-right: 98.72vw solid white;
    }
    @include media(xl) {
      border-right: 98.95vw solid white;
    }
  }

  .home__container {
    padding-top: 1rem;
    .ant-row {
      display: flex;
      justify-content: center !important;
    }
    .home__intro {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 75px !important;
      @include media(xs) {
        padding: 8px;
      }
      @include media(xl) {
        padding: 10px;
      }
      .intro__heading {
        color: white;
        font-weight: 400;
        font-size: 2rem !important;
        @include media(xs) {
          font-size: 1.8rem !important;
          width: 300px !important;
        }
        @include media(md) {
          font-size: 2.2rem !important;
        }
        @include media(lg) {
          font-size: 2rem !important;
          padding-top: 1rem;
        }
        @include media(xl) {
          font-size: 3.5rem !important;
        }
      }

      .intro__sub-heading {
        color: white;
        font-weight: 300;
        font-size: 1.25rem !important;
        @include media(xs) {
          font-size: 1rem !important;
          width: 300px !important;
        }
        @include media(xl) {
          font-size: 1.5rem !important;
        }
      }
    }
  }
}
