@import "../../styles/main.scss";

.modal__container {
  width: 960px !important;
    @media screen and (max-width: 320px) {
    & {
      width: 95% !important;
    }
  }
}
