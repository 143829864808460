@import '../../../styles/main.scss';

  .step-one__container {
    @include column;
    text-align: left;
    @include media(xl) {
      margin: 15px !important;
    }
    .step-one__card {
      width: 640px;
      @include column;
      margin: 8px !important;
      @media only screen and (max-width: 320px) {
        width: 280px !important;
        padding: 5px !important;
        margin: 8px !important;
      }
      @media only screen and (max-width: 425px) {
        width: 300px !important;
        padding: 5px !important;
        margin: 8px !important;
      }

    }
    .step-one__img {
      margin: 0 1rem;
      @media only screen and (max-width: 425px) {
        margin: 0 0.3rem;
      }
    }
    .step-one__radio {
      @include flex-center;
    }
  }