@import "./variables";

@mixin media($media) {
    @if $media == xs {
        @media (max-width: $breakpoint-sm) {
            @content;
        }
    } @else if $media == sm {
        @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
            @content;
        }
    } @else if $media == md {
        @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
            @content;
        }
    } @else if $media == lg {
        @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
            @content;
        }
    } @else if $media == xl {
        @media (min-width: $breakpoint-xl) and (max-width: $breakpoint-xxl) {
            @content;
        }
    }  @else if $media == xxl {
        @media (min-width: $breakpoint-xxl) {
            @content;
        }
    } 
}

@mixin mediaHeight($media) {
    @if $media == md {
        @media (max-height: 850px) {
            @content;
        }
    }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin sub-heading {
  color: $font-color;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 600px;

  @media only screen and (min-width: 576px) {
    & {
      font-size: 1.2rem;
    }
  }
}

@mixin text {
  font-size: 1rem;
  color: $font-color;
}

@mixin icon {
  @include flex-center;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: lighten($primary-color, 35%);

  font-size: 2.5rem;
  color: $primary-color;
}

@mixin card {
  padding: 2rem 0.5rem;
  text-align: center;
  border: $border !important;
  transition: 0.5s all ease-in-out;
}