@import '../../styles/main.scss';

.app-footer {
  background: $gradient-bg !important;
  padding: 0 !important;

  .app-header__logo {
    height: 75px;
    font-size: 24px;
    h2 {
      color: white !important;
      font-weight: 400;
    }
    @include media(xs) {
      padding: 0 !important;
    }
    .app-header__img {
      width: 180px;
      @include media(sm) {
        width: 200px !important;
      }
      @include media(lg) {
        width: 180px !important;
      }
      @include media(xl) {
        width: 240px !important;
      }
    }
  }

  .app-footer__content {
    padding: 5rem 0 5rem 0;
    .app-footer__content-row {
      @include media(xs) {
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    .app-footer__logo {
      justify-content: flex-start;
    }

    .app-footer__subscribe {
      .app-footer__subscribe-title {
        color: white;
        margin-bottom: 1.5rem;
      }
      .app-footer__subscribe-list {
        padding-left: 1.5rem;
      }
      .app-footer__subscribe-icon {
        padding-left: 0.8rem;
        color: white;
        font-size: 45px;
      }
      .app-footer__subscribe-text {
        color: white;
        font-size: 0.8rem;
        font-weight: 100 !important;
        margin-bottom: 1.5rem;
        @include media(xl) {
          font-size: 0.9rem;
        }
      }

      .app-footer__subscribe-input {
        .ant-input-group-addon {
          color: white;
          border-color: $primary-color;
          background-color: $primary-color;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  .app-footer__copyright {
    color: white;
    font-size: 0.8rem;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 1px solid lighten($footer-color, 5%);
  }
}
