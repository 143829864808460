.landing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;

    @media only screen and (min-width: 768px) {
        gap: 32px;
      }
}