@import '../../styles/main.scss';

.login__page {
  display: grid;
  grid-template-columns: 2fr 70%;
  align-items: center;
  grid-gap: 96px;
  margin: 20px 20px 0 20px;

  @include media(xs) {
    display: flex;
    flex-direction: column;
  }

  @include media(md) {
    grid-template-columns: 2fr 95%;
    grid-gap: 20px;
  }

  @include media(xl) {
    grid-template-columns: 2fr 80%;
  }

  &_banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 96vh;
    box-shadow: $banner-shadow;
    background-color: $gradient-bg;
    padding: 40px 20px;
    border-radius: 4px;

    @include media(xs) {
      display: none;
    }

    &_title {
      font-size: 34px !important;
      font-weight: 500;
      color: #fff;

      @include media(md) {
        font-size: 22px !important;
      }

      @include media(xl) {
        font-size: 24px !important;
      }
    }

    &_logo {
      width: 240px;
    }
  }

  &_card {
    width: 80%;

    @include media(xs) {
      margin-top: 50px;
    }
  }
}