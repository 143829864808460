@import '../../../styles/main.scss';

.step-three {
  width: 44%;
  .form-work__input {
    width: 100%;
  }

  @media only screen and (max-width: 425px) {
    width: 94%;
  }
}
