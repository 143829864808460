@import '../../../styles/main.scss';

.step-five__container {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  p{
    font-size: 18px;
  }
  img {
    width: 120px;
    margin-bottom: 100px;
  }
  .step-five__group {
    display: flex;
  }
  .step-five__card {
    width: 250px;
    @include column;
    text-align: center !important;
    align-items: center !important;
    margin: 1rem;
    p, h2 {
      margin: 3px 0 !important;
      padding: 0 !important;
    }
    .step-five__img {
      width: 80px !important;
    }
    .step-five__icon {
      display: flex;
      align-items: center;
      .step-five__icon-icon {
        margin: 0 10px !important;
        font-size: 17px !important;
        color: #5a5a5a;
      }
    }
    .step-five__radio {
      display: flex;
      justify-content: center;
      margin: 10px 0 0 !important;
    }
    .step-five__text, .step-five__radio {
      display: flex;
      justify-content: center;
      h4 {
        padding: 0;
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (min-width: 1200px) {
}
