@import '../../../styles/main.scss';

.step-six {
  width: 45%;
  margin: 0 0 1.5rem;
  @media only screen and (min-width: 1200px) {
  }
}

.ant-form-vertical .ant-form-item-label {
  padding: 0px !important;
}

.step-six_icon {
  .ant-form-item-no-colon::before {
    color: red;
    font-weight: 900; content: "* " !important;
  }
}