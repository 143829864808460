.modal {
  display: flex !important;
  .modal__section {
    display: flex !important;
    .modal__col {
      padding: 0 20px !important;
      .modal__col_docs {
        display: flex;
        flex-direction: column !important;
      }
    }
  }
}
