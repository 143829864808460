@import '../../../styles/main.scss';

.product__card_outlined {
  @include card;
  height: 280px !important;
  width: 254px !important;
  border-radius: 4px !important;
  margin: 0.5rem !important;
  box-shadow: 0px 9px 30px rgb(200 200 200 / 30%);
  border: 1px solid $secondary-color !important;
  &:hover {
    transform: scale(1.02);
  }

  @include media(xs) {
    height: 265px !important;
    width: 225px !important;
  }
  @include media(md) {
    height: 270px !important;
    width: 226px !important;
  }
  @include media(xl) {
    height: 300px !important;
    width: 250px !important;
    margin: 1.5rem !important;
  }
}
.product__card {
  @include card;
  height: 280px !important;
  width: 254px !important;
  border-radius: 4px !important;
  margin: 0.5rem !important;
  border: none !important;
  box-shadow: 0px 9px 30px rgb(200 200 200 / 30%);

  &:hover {
    transform: scale(1);
  }
  @include media(xs) {
    height: 265px !important;
    width: 225px !important;
  }
  @include media(md) {
    height: 270px !important;
    width: 226px !important;
  }
  @include media(xl) {
    height: 300px !important;
    width: 250px !important;
    margin: 1.5rem !important;
  }
}
