@import '../../styles/main.scss';

.spinner__center {
  margin: 15%;
}
.register {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  padding-bottom: 80px !important;
  .register__container-title{
    display: flex;
    justify-content: center;
    text-align: center;
    .register__title {
      font-weight: 300;
      margin: 1rem auto;
      font-size: 32px !important;
      @media only screen and (max-width: 425px) {
      }

    }

    .register__img {
      width: 180px;
      height: 160px;
    }

    h1 {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
    h4 {
      font-size: 15px;
      font-weight: 100;
      margin-bottom: 2rem !important;
    }
  }

  @media only screen and (min-width: 1400px) {
  }

  @media only screen and (min-width: 1200px) {
  }
}
