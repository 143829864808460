@import '../../styles/main.scss';

.app-icon {
  @include flex-center;
  display: inline-flex !important;
  color: $primary-color !important;
  border: 1px solid #3ecf8e;
  width: 250px !important;

  .anticon {
    font-size: 2.5rem;
  }
}
