@import '../../styles/main.scss';

.work-steps {
  padding-bottom: 80px !important;
  .step-content__image {
    text-align: center;
    margin: 1.8rem 0;
  }
  .step-content__header {
    @include column;
    text-align: center;
    margin: 1.4rem 0;
    h1 {
      margin: 0;
      font-size: 25px;
      font-weight: 500;
      @media only screen and (max-width: 425px) {
        & {
          font-size: 22px !important;
        }
      }
    }
    h4 {
      margin: 0;
      font-size: 15px;
      font-weight: 300;
    }
  }
  .step-content__content {
    margin: 1rem 0.4rem;
    @include flex-center;
    justify-content: center;
    .children {
      color: #03C988 !important;
    }
  }
}
