@import '../../styles/main.scss';

.about {
  @include flex-center;
  text-align: center;
  .about-heading {
    flex-direction: column;
    font-size: 2.5rem;
    @include media(xs) {
      font-size: 1.8rem;
      margin-top: 350px;
    }
    @include media(sm) {
      font-size: 2.5rem;
      margin-top: 200px;
    }
    @include media(md) {
      font-size: 2.5rem;
      margin-top: 200px;
    }
    @include media(xl) {
      font-size: 2.5rem;
      margin-top: 140px;
    }
  }
}
