@import "../../styles/main.scss";

.table_clients {
  margin: 40px 0 !important;
}
.modal__title {
  margin: 0 30px 0 0 !important
}
.btn-green {
  background: $secondary-color !important;
  color: white !important;
  margin: 0 35px;
}

h1 {
  font-size: 40px !important;
}

.btn-light {
  border: 1px solid $primary-color!important;
}
