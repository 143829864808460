@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

// Color
$gradient-bg: #13005A;;
$card-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
$banner-shadow: 1px 8px 32px -4px rgb(94,98,156,0.8);

$font-color: rgb(94, 98, 156);
$footer-color: #2d2d2d;

$border: 1px solid #ddd;

// Font
$primary-font: 'Poppins', sans-serif;

$primary-color: #13005A;
$secondary-color: #03C988;
$disabled-color: rgb(223, 222, 222);

//Size
$header-size: 80px;

// Breakpoints
$breakpoint-xs: 320px;
$breakpoint-sm: 767px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;
$breakpoint-xxl: 1920px;