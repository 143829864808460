@import '../../../styles/main.scss';

.none-form{
    .content-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    h2{
        text-align: center;
        color: rgb(0, 0, 0, 0.15);
        padding: 16px 0px;
    }
    img{
        width: 75%;
        filter: grayscale(1);
        opacity: 0.15;
        align-items: center;
    }
}
}


