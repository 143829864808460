@import '~antd/dist/antd.css';
@import './styles/main.scss';

main {
  background: white;
}

*,
html,
body {
  margin: 0;
  padding: 0;
  font-family: $primary-font;
}

//Inputs
.ant-select:not(.ant-select-customize-input) {
  border-radius: 0.3rem;
  height: 38px !important;
}
.ant-select-selector,
.ant-input-wrapper .ant-input-group {
  height: 38px !important;
}
.ant-input {
  height: 38px !important;
  font-size: 13px !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 13px !important;
}
.ant-input-affix-wrapper {
  padding: 0px 11px !important;
  font-size: 13px !important;
}
.ant-form-item {
  font-size: 13px !important;
  @include media(xs) {
    margin-bottom: 10px;
  }
  @include media(sm) {
    margin-bottom: 5px;
  }
  @include media(md) {
    margin-bottom: 3px;
  }
  @include media(lg) {
    margin-bottom: 2px;
  }
}

// Cards
.ant-card-meta-title {
  white-space: inherit !important;
  font-size: 1rem !important;
}
.ant-card-meta-description {
  white-space: inherit !important;
  font-size: 1rem;
  @include media(xs) {
    margin: 20px 0 !important;
  }
}
.ant-card-cover {
  padding-top: 2.5rem;
}

.app-icon {
  padding-top: 1rem;
  border: none;
  width: 150px !important;
}
//Steps

.ant-steps-vertical {
  display: flex;
  flex-direction: row !important;
  margin: 20px;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: $primary-color !important;
  border-color: rgb(199, 196, 196) !important;
}

.ant-steps-vertical .ant-steps-item {
  @media only screen and (min-width: 1200px) {
    flex: 0 !important;
  }
}

.ant-steps.ant-steps-horizontal.step__steps.ant-steps-label-horizontal {
  width: 50%;
}

.ant-modal-body {
  padding: 2rem 1rem 0.2rem 1rem !important;
}