@import '../../../styles/main.scss';

.about__card {
  @include card;
  padding: 1rem !important;
  border: none !important;
  margin: 0px 32px!important;

  @include media(xs) {
    padding: 0.5rem !important;
    margin:0px 24px!important;
  }

  .about__card-content_step {
    display: flex !important;
    justify-content: center !important;
  }

  .about__card-step {
    width: 150px;
    border-radius: 16px;
    background-image: linear-gradient(to right, #03c988 0%, #01005a59 80%, #ffffff 100%);
    padding: 8px 32px;
    text-align: center;
    margin: 0px 0px 32px 0px;
    background-size: 200% auto;
    transition: 0.5s;
    color: white;
  }
}
