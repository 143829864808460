@import '../../../styles/main.scss';

.step-two__container {
  @include column;
  text-align: left;
  .step-two__card {
    width: 640px;
    @include column;
    margin: 1rem 0;
    @media only screen and (max-width: 425px) {
      width: 98%;
    }
    .step-two__content {
      display: flex !important;
    }
    .step-two__text, .step-two__radio {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h4 {
        padding: 0;
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (min-width: 1200px) {
}
