@import '../../../styles/main.scss';

.calculator {
  display: flex;
  position: absolute;
  height: 60vh;
  z-index: 1;
  justify-content: center !important;
  box-shadow: 1px 4px 3px 3px rgba(212, 211, 211, 0.25) !important;
  @media only screen and (min-width: 320px) {
    & {
      border-radius: 16px !important;
    }
  }
  @include media(xs) {
    margin: auto !important;
    height: 580px;
  }
  @include media(sm) {
    height: 480px;
  }
  @include media(md) {
    height: 460px;
  }
  @include media(lg) {
    height: 480px;
  }
  @include media(xl) {
    height: 76vh;
  }

  .ant-card-body {
    @include media(xs) {
      padding: 1rem 1.8rem !important;
    }
    @include media(md) {
      padding: 1.5rem 2rem !important;
    }
    @include media(lg) {
      padding: 1.2rem 1.5rem !important;
    }
    @include media(xl) {
      padding: 1.4rem 1.8rem !important;
    }
  }
  .calculator__container-title {
    display: flex;
    justify-content: center !important;
    text-align: center;

    .calculator__title {
      font-weight: 300;
      font-size: 1rem !important;
      @media only screen and (min-width: 320px) {
        & {
          font-size: 22px !important;
          margin: 0 0 30px;
        }
      }
      @include media(md) {
        font-size: 1.4rem !important;
        margin: 0 0 15px;
      }
      @include media(lg) {
        font-size: 1.5rem !important;
        margin: 0 0 15px;
      }
      @include media(xl) {
        font-size: 1.8rem !important;
        margin: 1px 0px 4px;
      }
    }
  }
  .calculator__container-action {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-top: 2px;
    @include media(md) {
      margin-top: 2px;
    }
    .btn__secondary {
      margin: 0;
      padding: 0;
    }
    .btn__primary-outlined {
      color: $primary-color;
      border: 1px solid $primary-color !important;
      margin: 0;
      padding: 0;
    }
  }

  .calculator__input-cuota {
    text-align: center;
    font-size: 1.5rem !important;
    font-weight: 300;
    border-radius: 0.3rem;
    height: 42px !important;
    @include media(xs) {
      font-size: 1.5rem !important;
    }
    @include media(xl) {
      margin: 0;
      padding: 0;
    }
  }

  .calculator__info {
    display: flex;
    justify-content: center;
    text-align: center;
    small {
      margin: 0 4px 0 0;
      color: $primary-color;
    }
    @include media(xs) {
      font-size: 12px;
      margin-top: 8px;
    }
    @include media(sm) {
      font-size: 15px;
      margin-top: 8px;
    }
    @include media(lg) {
      margin: 0;
      padding: 0;
    }
  }


}
