@import '../../styles/main.scss';

.app-header {
  @include flex-center;
  padding: 0 !important;
  height: $header-size !important;
  background: $gradient-bg !important;
  z-index: 1;

  .app-header__content {
    @include flex-center;
    justify-content: space-between !important;
    align-content: space-between !important;
    align-items: space-between !important;
    justify-items: space-between !important;

    .app-header__logo {
      font-size: 24px;
      height: 90px;
      .app-header__logo__img {
        width: 150px;
        height: 80px;
        @include media(xs) {
          margin: 15px;
          width: 100px;
          height: 60px;
        }
      }
      h2 {
        color: white !important;
        font-weight: 300;
      }
    }

    .app-header__menu {
      display: flex;
      width: 280px;
      color: white;
      font-size: medium;
      font-weight: bold;
      align-self: right;
      background: transparent;
      border-bottom: none;
      line-height: 45px;
      justify-content: flex-end;

      .app-header__menu-icon {
        font-size: 1.5rem;
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        margin: auto;

        &:hover {
          color: white !important;
        }
      }

      .ant-menu-item {
        .app-header__menu-item {
          color: white;
        }
      }
    }
  }
}

.ant-menu-horizontal > .ant-menu-item a {
  @media only screen and (min-width: 320px) {
    & {
      color: rgb(255 255 255 / 85%) !important;
    }
  }
}
