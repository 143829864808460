@import '../../styles/main.scss';

.request {
  @include flex-center;
  @include column;
  .request__image {
    width: 200px;
  }
  .request__title {
    @media only screen and (max-width: 320px) {
      & {
        font-size: 1.5rem !important;
      }
    }
  }
}
