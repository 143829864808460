@import '../../styles/main.scss';

.app-button {
  width: 100% !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  text-transform: uppercase;
  box-shadow: 0 8px 9px 0 rgba(96, 94, 94, 0.17) !important;
  &:hover {
    transform: scale(1.02);
  }
  &:disabled {
    background: $disabled-color !important;
    transform: none;
    box-shadow: none !important;
  }
}
.btn__secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $secondary-color !important;
  color: white !important;
  font-weight: 300 !important;
  text-align: center;
  @include media(xs) {
    display: flex;
    font-size: 0.8rem !important;
    width: 240px !important;
    height: 44px !important;
    text-align: center;
  }
  @include media(sm) {
    display: flex;
    width: 500px !important;
    height: 48px !important;
    text-align: center;
    font-size: 0.8rem !important;
  }
  @include media(md) {
    display: flex;
    width: 240px !important;
    height: 50px !important;
    text-align: center;
  }
  @include media(xl) {
    display: flex;
    width: 350px !important;
    height: 56px !important;
  }
}

.btn__primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $secondary-color !important;
  color: white !important;
  font-weight: 300 !important;
  @include media(xs) {
    display: flex;
    width: 240px !important;
    height: 44px !important;
    text-align: center;
    font-size: 0.8rem !important;
  }
  @include media(sm) {
    display: flex;
    width: 500px !important;
    height: 48px !important;
    text-align: center;
    font-size: 0.8rem !important;
  }
  @include media(md) {
    display: flex;
    width: 240px !important;
    height: 50px !important;
    text-align: center;
  }
  @include media(lg) {
    display: flex;
    width: 240px !important;
    height: 50px !important;
    text-align: center;
    }
  @include media(xl) {
    display: flex;
    width: 350px !important;
    height: 56px !important;
    text-align: center;
  }
}

.btn__link {
  box-shadow: none !important;
  font-weight: normal !important;
}
