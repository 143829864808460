@import '../../styles/main.scss';

@import '../../styles/main.scss';

.login__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  background-color: white;
  overflow: hidden;
  margin: 0 auto;
}
#login__form {
  width: 1000px !important;
  padding: 60px;
  @media screen and (max-width: 320px) {
    & {
     padding: 20px;
    }
  }
}
#login__form p.form__title {
  font-weight: 300;
  font-size: 33px !important;
  margin-bottom: 0 !important;
  @media screen and (max-width: 320px) {
    & {
      font-size: 25px !important;
    }
  }
}

#login__form p.form__subtitle {
  font-size: 15px !important;
  margin-bottom: 40px !important;
}

#login__form .btn__container {
  display: flex;
  justify-content: center;
  align-content: center;
}

#login__form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-control-input-content {
  text-align: left;
}

#login__form small {
  color: red;
}

.login__img {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: white;
}
.login__img img {
  display: block;
  width: 400px;
}
@media screen and (max-width: 1023px) {
  .login__box {
    flex-direction: column;
    box-shadow: none;
  }
  .login__img img{
    max-width: 100%;
    width: 400px;
  }
  #login__form {
    max-width: 100%;
  }
}
