.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;


  &--fluid {
    max-width: none !important;
  }
}

@media only screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

