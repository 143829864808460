@import '../styles/main.scss';

.layout {
  background-color: 'white';
  .logo_nav {
    width: 80px;
  }
}

.sider {
    background: $primary-color !important;
}
.menu {
    display: flex;
    flex-direction: column;
    background: $secondary-color !important;
    text-align: center;
    padding: 10px 0 !important;
    .icon {
      color: white !important;
      font-weight: 700 !important;
    }
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #d0e7ff !important;
}

#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #3ecf8e;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 52px;
    margin: 16px;
    background: $primary-color;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }