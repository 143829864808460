@import '../../../styles/main.scss';

.step-four {
  width: 44%;
  padding: 0 0 1.5rem 0;
  .card-four_item {
    width: 100%;
  }
  @media only screen and (max-width: 425px) {
    width: 94%;
  }
}
